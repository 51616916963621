import app1 from './Phones-Desktop.svg';
import logo from './delivr-logo.svg';
import './fonts/DMSans-Regular.ttf';

import './App.css';
import RequestAccess from "./components/RequestAccess";

function App() {
  return (
    <div className="App">
      <section className="App-container">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
        </header>
        <section className="App-content">
          <div className="App-content-left">
            <div className="App-title">
              Deliveries made <span>easy</span>
            </div>
            <div className="App-description">
              Delivr is still under development, but we are allowing early access to a select number of users. If you are interested in gaining early access to Delivr please submit the form below, and we will be in touch soon.
            </div>
            <RequestAccess/>
          </div>
          <div className="App-image-container">
            <img src={app1} className="App-image"/>
          </div>
        </section>
      </section>
    </div>
  );
}

export default App;
