function RequestAccess() {
  return (
    <form action="https://delivr.us7.list-manage.com/subscribe/post?u=16f2a43e4d730f2e35e29bae6&amp;id=e799eeeeb9"
          method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
          target="_blank" noValidate>
      <div style={styles.access}>
        <input type="email" style={styles.input} placeholder="Enter your email address" name="EMAIL" id="mce-EMAIL"
               required/>
        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
          <input type="text" name="b_16f2a43e4d730f2e35e29bae6_e799eeeeb9" tabindex="-1" value=""/>
        </div>
        <button style={styles.button} type="submit">REQUEST EARLY ACCESS</button>
      </div>
    </form>
  );
}

const styles = {
  access: {
    background: "#1E1E1E",
    borderRadius: "24px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    background: "#FFFFFF",
    borderRadius: "15px",
    padding: "22px 20px",
    cursor: "pointer",
    fontSize: 15,
    fontWeight: "bold",
    minWidth: "235px",
    flexGrow: 1
  },
  input: {
    flexGrow: 3,
    background: "#616161",
    borderRadius: "15px",
    padding: "22px 12px",
    border: "none",
    color: "rgba(255, 255, 255, 0.5)",
    fontSize: 24,
    width: "100%",
    boxSizing: "border-box"
  }
};

export default RequestAccess;
